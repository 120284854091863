
import { defineComponent, ref, watch } from 'vue';
import useModel from '@/hooks/useModel';
import { dealerApplicableTypeClassMap as dealerApplicableTypeClassMapAPI } from '@/API/localRegistration';
import useWait from '@/utils/payment/useWait';
export default defineComponent({
    name: 'ApplicableTypeClassSelector',
    props: {
        bu: {
            type: String,
        },
        value: {
            type: String,
        },
    },
    setup(props) {
        const valueModel = useModel(props, 'value');
        const wait = useWait();

        const typeClassMap = ref();

        dealerApplicableTypeClassMapAPI().then((data) => {
            typeClassMap.value = data;
            wait.toRun?.()
        });

        const typeClasses = ref<string[]>([]);

        watch(
            () => props.bu,
            async (bu?: string) => {
                await wait;
                typeClasses.value = bu ? typeClassMap.value?.[bu] ?? [] : [];
                if (valueModel.value !== undefined) {
                    valueModel.value = typeClasses.value.includes(
                        valueModel.value as string
                    )
                        ? valueModel.value
                        : undefined;
                }
            },
            {
                immediate: true
            }
        );

        return {
            valueModel,
            typeClasses,
        };
    },
});
