import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, _mergeProps({
    value: _ctx.valueModel,
    disabled: !_ctx.rules.length
  }, _ctx.$attrs, { onChange: _ctx.getRuleName }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules, (rule) => {
        return (_openBlock(), _createBlock(_component_a_select_option, {
          key: rule[_ctx.optionKey],
          value: rule[_ctx.optionKey]
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(rule[_ctx.optionTitle]), 1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["value", "disabled", "onChange"]))
}