
import { defineComponent, ref , watch} from 'vue'
import type { PropType } from 'vue'
import useModel from '@/hooks/useModel'
import {
  getCalculationRuleSelection as getCalculationRuleSelectionAPI
} from '@/API/localRegistration'

export default defineComponent({
  name: 'CalculationRuleSelector',
  props: {
    optionTitle: {
      type: String as PropType<string>,
      default: 'name'
    },
    optionKey: {
      type: String as PropType<string>,
      default: 'id'
    },
    bu: {
      type: String
    },
    value: {
      type: String,
    }
  },
  setup(props, {emit}) {
    const valueModel = useModel(props, 'value');
    const rules = ref<{id: number; name: string}[]>([])

    watch(
      () => props.bu,
      async (bu?: string) => {
        rules.value = []
        if (bu) {
          rules.value = await getCalculationRuleSelectionAPI(bu)
        }
        valueModel.value = undefined
      },
      {
        immediate: true
      }
    )

    const getRuleName = (value: string) => {
      const name = rules.value.filter((rule: any) => rule.id === value)[0].name
      emit('get-name', name)
    }

    return {
      rules,
      valueModel,
      getRuleName
    }
  }
})
